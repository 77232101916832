import Menu from './menu';
import { breakpoints } from './constants';

/**
 * Class representing the filter bar extending main menu navigation.
 */
export default class FilterBar extends Menu {
  constructor(filterBarSelector) {
    super(filterBarSelector);
    this.mobileView;

    this.handleMobileChange();
    window.addEventListener('resize', this.handleMobileChange);
  }

  spreadMenuDropdown = () => {
    if (this.navbar) {
      // open all details on mobile view
      this.menus.forEach(
        (thisDetail) => {
          thisDetail.open = true;
          console.log('open');
        },
      );
    }
  };

  handleSorterChange = () => {
    document
      .querySelectorAll('.filter-bar__sorter-input-toggle')
      .forEach(
        (e) => {
          e.addEventListener(
            'change',
            (_) => {
              const formId = e.getAttribute('data-form-id');
              document.getElementById(formId).submit();
            },
          );
        },
      );
  };

  handleMobileChange = () => {
    const breakpoint = `(max -width: ${breakpoints.DESKTOP_BREAKPOINT})`;
    this.mobileView = window.matchMedia(breakpoint);

    // Prevent opening filter menu or search field after going back on page for mobile
    this.handleJumpLink(document.querySelectorAll('.erase-trace'));

    if (this.mobileView.matches) {
      // Stop closing openthis.menus if the user clicks on a non-menu item
      document.removeEventListener('pointerdown', super.handleNonMenuClick);

      // Stop closing openthis.menus if the esc key is pressed
      document.removeEventListener('keydown', super.handleEscapeKey);

      // Open all filter menu on mobile
      this.spreadMenuDropdown(this.navbar, this.menus);

      // Freeze mobile screen on opening filter
      window.addEventListener('hashchange', this.handleScrollAndOverlay);
    } else {
      this.menus.forEach(
        (thisDetail) => {
          if (thisDetail.open) {
            thisDetail.removeAttribute('open');
          }
        },
      );
      this.asyncDropdown(this.navbar);

      this.handleSorterChange();
    }
  };
}
