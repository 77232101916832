document.addEventListener('DOMContentLoaded', () => {
  const sidenav = document.getElementById('sidenav');
  const links = document.querySelectorAll('.aside_links');
  const sections = document.querySelectorAll('.section-side');
  const container = document.querySelector('.main-side');

  const dropdownBtn = document.getElementById('dropdownBtn');
  const dropdownContent = document.getElementById('dropdownContent');

  let lastScrollTop = 0;
  const navHeight = 80; // Height of primary navigation
  let isFixed = false; // Flag to manage sidenav position
  const transitionBuffer = 10; // Buffer to reduce rapid toggling

  const firstLinkElement = document.querySelector('.aside-menu .dropdown-content a');
  if (!firstLinkElement) {
    return;
  }
  const firstLinkText = firstLinkElement.textContent;

  const observerOptions = {
    root: null,
    rootMargin: '0px',
    threshold: 0.1, // 10% of the element is visible
  };

  const observerCallBack = (entries) => {
    entries.forEach((entry) => {
      const links = dropdownContent.querySelectorAll('.aside_links');
      if (entry.isIntersecting) {
        links.forEach((link) => {
          link.classList.remove('active');
          if (link.getAttribute('href').substring(1) === entry.target.id) {
            link.classList.add('active');
            if (window.innerWidth <= 768) {
              dropdownBtn.textContent = link.textContent;
            }
          }
        });
      }
    });
  };

  const observer = new IntersectionObserver(observerCallBack, observerOptions);
  sections.forEach((section) => observer.observe(section));

  // Smooth scrolling for links with consideration of scroll direction
  links.forEach((link) => {
    link.addEventListener('click', function (e) {
      e.preventDefault();
      const target = document.querySelector(this.getAttribute('href'));
      const currentScrollTop = window.scrollY;
      const targetPosition = target.getBoundingClientRect().top + window.scrollY;

      let offset = 0;

      if (targetPosition < currentScrollTop) {
        // Scrolling up
        offset = navHeight;
      }

      const offsetPosition = targetPosition - offset;

      window.scrollTo({
        top: offsetPosition,
        behavior: 'smooth',
      });
      dropdownContent.classList.remove('show');
    });
  });

  // Dropdown toggle for mobile view
  dropdownBtn.addEventListener('click', () => {
    dropdownContent.classList.toggle('show');
  });

  const handleResize = () => {
    if (window.innerWidth <= 768) {
      dropdownBtn.style.display = 'block';
      dropdownBtn.textContent = firstLinkText;
      dropdownContent.classList.remove('show');
    } else {
      dropdownBtn.style.display = 'none';
      dropdownContent.classList.remove('show');
      links.forEach((link) => link.classList.remove('hidden'));
    }
  };

  const handleScroll = () => {
    const containerRect = container.getBoundingClientRect();
    const scrollTop = window.scrollY;

    const insideContainer = containerRect.top <= transitionBuffer && containerRect.bottom > navHeight + transitionBuffer;

    // Mobile View
    if (window.innerWidth <= 768) {
      dropdownBtn.style.display = 'block';

      if (insideContainer) {
        // Inside the container
        if (!isFixed || sidenav.style.top !== '0') {
          sidenav.style.position = 'fixed';
          sidenav.style.top = '0';
          isFixed = true;
        }
        // Adjust top position based on scroll direction
        if (scrollTop > lastScrollTop) {
          // Scrolling down
          sidenav.style.top = '0'; // Move to top when scrolling down
        } else {
          // Scrolling up
          sidenav.style.top = `${navHeight}px`; // Offset when scrolling up
        }
      } else if (isFixed || sidenav.style.position !== 'relative') {
        // Outside the container and currently fixed
        sidenav.style.position = 'relative';
        sidenav.style.top = '0'; // Ensure top is 0 when position is relative
        isFixed = false;
      }
    } else {
      // Desktop View
      if (insideContainer) {
        // Inside the container
        if (!isFixed) {
          sidenav.style.position = 'fixed';
          sidenav.style.top = `${navHeight}px`;
          isFixed = true;
        }
        // Adjust top position based on scroll direction
        if (scrollTop > lastScrollTop) {
          // Scrolling down
          sidenav.style.top = '0'; // Move to top when scrolling down
        } else {
          // Scrolling up
          sidenav.style.top = `${navHeight}px`; // Offset when scrolling up
        }
      } else if (isFixed || sidenav.style.position !== 'absolute') {
        // Outside the container and currently fixed
        sidenav.style.position = 'absolute';
        sidenav.style.top = '0';
        isFixed = false;
      }
    }

    lastScrollTop = scrollTop <= 0 ? 0 : scrollTop; // Update last scroll position
  };

  const debouncedHandleScroll = debounce(handleScroll, 10);

  window.addEventListener('resize', handleResize);
  window.addEventListener('scroll', debouncedHandleScroll);

  // Initial setup for mobile view
  handleResize();

  function debounce(func, wait) {
    let timeout;
    return function executedFunction(...args) {
      const later = () => {
        clearTimeout(timeout);
        func(...args);
      };

      clearTimeout(timeout);
      timeout = setTimeout(later, wait);
    };
  }
});
